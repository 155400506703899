<template>
  <div class="main-wrap" ref="mainWrapBlock">
    <div class="main-login" v-if="enterFormVisible" ref="loginBlock">
      <Login @submit="openLogin" v-if="enterFormVisible === 'login'" />
      <Registration @submit="openRegistration" v-if="enterFormVisible === 'registration'" />
    </div>

    <div class="main">
      <div class="main__row">
        <div class="main__left">
          <div class="main__move">INTER <span class="text-outline">SHOP</span></div>
          <div class="main__move">INTER <span class="text-outline">SHOP</span></div>
          <div class="main__move">INTER <span class="text-outline">SHOP</span></div>
          <div class="main__move">INTER <span class="text-outline">SHOP</span></div>
          <div class="main__move">INTER <span class="text-outline">SHOP</span></div>
        </div>

        <div class="main__center">

          <div class="navbar">
            <div class="user" @click="openLogin" v-if="!me">
              <div class="user__name">Вход</div>
            </div>
            <div class="user" @click="openRegistration" v-if="!me">
              <div class="user__name">Регистрация</div>
            </div>
            <div class="user" @click="openAccount" v-else>
              <div class="user__name">{{ me.name }}</div>
              <div class="user__account">
                <Currency :price="me.coins" type="yellow" />
              </div>
            </div>
          </div>

          <div class="content">
            <div class="content__header">
              <h1 class="h1">Мерч для своих</h1>
            </div>
            <div class="content__heart">
              <div class="content__heart-text" ref="textCircle">
                сделано с любовью •
              </div>
              <img src="@/assets/heart.png" alt="" class="content__heart-img">
            </div>
          </div>

          <div class="cart" @click="openCart" role="button">
            <div class="cart__icon">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect
                  x="7.3335"
                  y="15.6665"
                  width="25.3333"
                  height="20.3333"
                  rx="2"
                  stroke="white"
                  stroke-width="2"
                />
                <path
                  d="M25 20V9C25 6.79086 23.2091 5 21 5H19C16.7909 5 15 6.79086 15 9V20"
                  stroke="white"
                  stroke-width="2"
                />
              </svg>
            </div>
            <div class="cart__text">
              Корзина
            </div>
          </div>
        </div>

        <div class="main__right">
          <div class="main__move">INTER <span class="text-outline">SHOP</span></div>
          <div class="main__move">INTER <span class="text-outline">SHOP</span></div>
          <div class="main__move">INTER <span class="text-outline">SHOP</span></div>
          <div class="main__move">INTER <span class="text-outline">SHOP</span></div>
          <div class="main__move">INTER <span class="text-outline">SHOP</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  onMounted, ref, computed, nextTick, watch,
} from 'vue';
import { useStore } from 'vuex';
import Login from '../Login.vue';
import Currency from '../Currency.vue';
import Registration from '../Registration.vue';

export default {
  name: 'Main',
  components: { Registration, Currency, Login },
  setup() {
    const store = useStore();
    const textCircle = ref();

    const me = computed(() => store.getters.getUser);

    onMounted(async () => {
      // text circle
      const text = textCircle.value.innerText;
      const textArr = text.split('');
      const textWrap = textArr.map((e, i, a) => {
        const last = i + 1 === a.length;

        const res = `
          <span class="circle" style="--rot:${i * 9}deg; ${last ? 'color:#F5D135;' : ''}">${e}</span>
          <span class="circle" style="--rot:${i * 9 + 180}deg; ${last ? 'color:#F5D135;' : ''}">${e}</span>
        `;

        return res;
      }).join('');
      textCircle.value.innerHTML = textWrap;
    });

    // cart
    const openCart = () => store.commit('modals/showCart');
    // account
    const openAccount = () => store.commit('modals/showAccount');

    const mainWrapBlock = ref();
    const loginBlock = ref();
    const enterFormVisible = computed(() => store.getters['modals/getEnterFormVisible']);

    watch(enterFormVisible, () => {
      nextTick(() => {
        mainWrapBlock.value.style.paddingTop = loginBlock.value ? `${loginBlock.value.scrollHeight || 0}px` : null;
      });
    });
    // login
    const openLogin = () => store.commit('modals/setEnterFormVisible', 'login');
    // registration
    const openRegistration = () => store.commit('modals/setEnterFormVisible', 'registration');

    return {
      textCircle,
      openLogin,
      enterFormVisible,
      me,
      openCart,
      openAccount,
      openRegistration,
      loginBlock,
      mainWrapBlock,
    };
  },
};
</script>

<style scoped lang="scss">
  .main-login {
    position: absolute;
    top: 0;
    padding: 65px 0;
    display: flex;
    flex-direction: column;
    gap: 28px;
    align-items: center;
    justify-content: center;
  }

  .main-wrap {
    background: linear-gradient(107.25deg, #F5D135 0%, #40F588 44.6%, #35E9F5 99.56%);
    height: 100vh;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: padding-top $transition;
    max-height: 100%;
  }

  .main {
    width: 100%;
    height: 100%;
    background: $dark;
    overflow: hidden;
    position: relative;

    &__row {
      display: flex;
      padding: 0 54px;
      max-height: 100%;
    }

    &__left,
    &__right {
      color: $primary;
      position: relative;
      width: 36px;
      user-select: none;
    }

    &__left {
      transform: rotate(180deg) translateY(-360px);
      .main__move {
        animation: move 5s infinite linear;
      }
    }
    &__right {
      .main__move {
        animation: move 5s infinite linear;
      }
    }

    &__move {
      writing-mode: vertical-lr;
      font-size: 36px;
      line-height: 100%;
      height: 360px;
      padding: 60px 0 0 0;
    }

    &__center {
      flex: 1;
      color: $text-white;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
  }

  .text-outline {
    color: black;
    text-shadow:
      -0   -2px 0   #FFFFFF,
      0   -2px 0   #FFFFFF,
      -0    2px 0   #FFFFFF,
      0    2px 0   #FFFFFF,
      -2px -0   0   #FFFFFF,
      2px -0   0   #FFFFFF,
      -2px  0   0   #FFFFFF,
      2px  0   0   #FFFFFF,
      -1px -2px 0   #FFFFFF,
      1px -2px 0   #FFFFFF,
      -1px  2px 0   #FFFFFF,
      1px  2px 0   #FFFFFF,
      -2px -1px 0   #FFFFFF,
      2px -1px 0   #FFFFFF,
      -2px  1px 0   #FFFFFF,
      2px  1px 0   #FFFFFF,
      -2px -2px 0   #FFFFFF,
      2px -2px 0   #FFFFFF,
      -2px  2px 0   #FFFFFF,
      2px  2px 0   #FFFFFF,
      -2px -2px 0   #FFFFFF,
      2px -2px 0   #FFFFFF,
      -2px  2px 0   #FFFFFF,
      2px  2px 0   #FFFFFF;
  }

  .navbar {
    width: 100%;
    margin: 48px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }

  .user {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    line-height: 100%;
    cursor: pointer;

    &__name {
      margin-bottom: 8px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__header {
      text-align: center;

      animation-name: vanishIn;
      animation-duration: 1s;
      animation-fill-mode: both;
    }

    &__heart {
      position: relative;
      user-select: none;

      &-text  {
        position: relative;
        width: 200px;
        height: 200px;
        line-height: 200px;
        border-radius: 50%;
        text-align: center;
        animation: love-rotate 15s infinite linear;

        @include xl {
          width: 160px;
          height: 160px;
          line-height: 160px;
        }
        @include lg {
          width: 96px;
          height: 96px;
          line-height: 96px;
        }
      }

      &-img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: heart-scale 5s infinite linear;
        width: initial;
      }
    }
  }

  .cart {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    margin: 48px 0;
    z-index: 1;
  }

  @keyframes vanishIn {
    0% {
      opacity: 0;
      transform-origin: 50% 50%;
      transform: scale(2, 2);
      filter: blur(1000px);
    }
    100% {
      opacity: 1;
      transform-origin: 50% 50%;
      transform: scale(1, 1);
      filter: blur(0px);
    }
  }

  @keyframes move {
    from { transform: translateY(0); }
    to { transform: translateY(-360px); }
  }

  @keyframes love-rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
  }

  @keyframes heart-scale {
    0% { transform: translate(-50%, -50%) scale(1.2); }
    50% { transform: translate(-50%, -50%) scale(0.8); }
    100% { transform: translate(-50%, -50%) scale(1.2); }
  }

  @include xl {
    @keyframes heart-scale {
      0% { transform: translate(-50%, -50%) scale(1); }
      50% { transform: translate(-50%, -50%) scale(0.6); }
      100% { transform: translate(-50%, -50%) scale(1); }
    }
  }
  @include lg {
    @keyframes heart-scale {
      0% { transform: translate(-50%, -50%) scale(0.8); }
      50% { transform: translate(-50%, -50%) scale(0.4); }
      100% { transform: translate(-50%, -50%) scale(0.8); }
    }
  }
</style>

<style lang="scss">
  .circle {
    display: inline-block;
    width: 25px;
    position: absolute;
    transform: translate(-12.5px, 0px) rotate(var(--rot)) translate(0, -110px);
    font-size: 22px;

    @include xl {
      transform: translate(-12.5px, 0px) rotate(var(--rot)) translate(0, -90px);
      font-size: 18px;
    }
    @include lg {
      transform: translate(-12.5px, 0px) rotate(var(--rot)) translate(0, -70px);
      font-size: 14px;
    }
  }
</style>
