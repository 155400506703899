<template>
  <Main />

  <Products :data="data" v-if="data" />

  <Footer />

  <transition name="modal">
    <ModalProduct v-if="showProductModal" />
  </transition>
  <transition name="modal">
    <ModalCart v-if="showCartModal" />
  </transition>
  <transition name="modal">
    <ModalAccount v-if="showAccountModal" />
  </transition>
</template>

<script>
import {
  ref, onMounted, computed,
} from 'vue';
import { useStore } from 'vuex';
import Main from '../components/sections/Main.vue';
import ModalProduct from '../components/Modal/ModalProduct.vue';
import ModalCart from '../components/Modal/ModalCart.vue';
import Products from '../components/sections/Products.vue';
import Footer from '../components/sections/Footer.vue';
import ModalAccount from '../components/Modal/ModalAccount.vue';

export default {
  name: 'Home',
  components: {
    ModalAccount,
    Footer,
    Products,
    ModalCart,
    ModalProduct,
    Main,
  },
  setup() {
    const store = useStore();
    const data = ref();
    const me = computed(() => store.getters.getUser);
    const showProductModal = computed(() => store.getters['modals/getProduct']);
    const showCartModal = computed(() => store.getters['modals/getCart']);
    const showAccountModal = computed(() => store.getters['modals/getAccount']);

    onMounted(async () => {
      await store.dispatch('products/getProducts');
      data.value = store.getters['products/getProducts'];

      if (store.getters.hasTokens) {
        await store.dispatch('getUser');
      }
    });

    return {
      data, me, showProductModal, showCartModal, showAccountModal,
    };
  },
};
</script>

<style lang="scss">
  .modal-enter-active {
    animation: bounce-in-top .8s both;
  }

  .modal-leave-active {
    animation: slide-out-bottom 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  @keyframes slide-out-bottom {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    100% {
      transform: translateY(1000px);
      opacity: 0;
    }
  }

  @keyframes bounce-in-top {
    0% {
      transform: translateY(-500px);
      animation-timing-function: ease-in;
      opacity: 0;
    }
    38% {
      transform: translateY(0);
      animation-timing-function: ease-out;
      opacity: 1;
    }
    55% {
      transform: translateY(-65px);
      animation-timing-function: ease-in;
    }
    72% {
      transform: translateY(0);
      animation-timing-function: ease-out;
    }
    81% {
      transform: translateY(-28px);
      animation-timing-function: ease-in;
    }
    90% {
      transform: translateY(0);
      animation-timing-function: ease-out;
    }
    95% {
      transform: translateY(-8px);
      animation-timing-function: ease-in;
    }
    100% {
      transform: translateY(0);
      animation-timing-function: ease-out;
    }
  }

</style>
