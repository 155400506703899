<template>
  <button :class="`btn btn_${type}`">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'Btn',
  props: {
    type: { type: String, values: ['primary', 'secondary'], default: 'primary' },
  },
};
</script>

<style scoped lang="scss">
  .btn {
    border: none;
    border-radius: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    padding: 6px 36px;
    cursor: pointer;

    &_primary {
      background: $primary;
    }

    &_secondary {
      background: $white;
    }
  }
</style>
