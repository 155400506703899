<template>
  <div class="products" v-if="data">
    <div class="products__row container">
      <div class="products__col" v-for="product in data" :key="product.id">
        <Product :data="product" :idx="product.idx + 1" @click="openProduct(product.id)" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import Product from '../Product.vue';

export default {
  name: 'Products',
  props: ['data'],
  components: { Product },
  setup() {
    const store = useStore();

    const showProductModal = computed(() => store.getters['modals/getProduct']);
    const productId = ref();

    const openProduct = (id) => {
      if (showProductModal.value) {
        store.commit('modals/hideProduct');
        setTimeout(() => store.commit('modals/showProduct', id), 300);
      } else store.commit('modals/showProduct', id);

      productId.value = id;
    };

    return { openProduct, productId };
  },
};
</script>

<style scoped lang="scss">
  // products
  .products {
  &__row {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
   }

  &__col {
     padding: 156px 0;
     width: 50%;
     display: flex;
     justify-content: center;
   }
  }
</style>
