<template>
  <modal-template @close="closeHandler">
    <div class="product-detail">
      <div class="product-detail__photos">

        <div class="product-detail__photos-scrollBox">
          <div class="product-detail__photos-nav">
            <div
              class="img-card"
              v-for="photo in data.photos"
              :key="photo.id"
              :style="`background: ${data.color}`"
            >
              <img :src="photo.img" alt="" @click="changeMainImg(photo.img)">
            </div>
          </div>
        </div>

        <div class="product-detail__photos-main">
          <div class="img-card" ref="refMainImg" :style="`background: ${data.color}`">
            <img :src="srcMainImg" alt="">
          </div>
        </div>

      </div>
      <div class="product-detail__info">

        <div class="product-detail__name"><h2 class="h2">{{ data.name }}</h2></div>

        <div class="product-detail__sizes" v-if="data.products?.length && data.products[0].size">
          <div class="product-detail__radio-list">
            <div class="product-detail__radio-item" v-for="item in sizes" :key="item">
              <input
                type="radio"
                :id="item"
                v-model="model.id"
                :disabled="!data.products.find((el) => el.size.name === item)"
                :value="data.products.find((el) => el.size.name === item)?.id"
              >
              <label :for="item">
                {{ item }}
              </label>
            </div>
          </div>
        </div>

        <div class="product-detail__price">
          <Currency :price="data.price" type="yellow" />
        </div>

        <div class="product-detail__actions">
          <div class="product-detail__actions-animate" ref="flipAnimateBlock">
            <btn type="primary" @click="toCartHandler">В корзину</btn>
            <btn type="primary" @click="toCartHandler">Добавлен</btn>
          </div>
        </div>

        <div class="product-detail__description" v-if="data.description">
          <h4>О товаре</h4>
          <p>{{ data.description }}</p>
        </div>

      </div>
    </div>
  </modal-template>
</template>

<script>
import { computed, ref, reactive } from 'vue';
import { useStore } from 'vuex';
import ModalTemplate from './ModalTemplate.vue';
import Currency from '../Currency.vue';
import Btn from '../Btn.vue';
import { animateCSS } from '../../utils/decorators';

export default {
  name: 'ModalProduct',
  components: { Btn, Currency, ModalTemplate },
  setup() {
    const store = useStore();

    // Data
    const mainProductId = computed(() => store.getters['modals/getProduct']);
    const data = computed(() => store.getters['products/getProducts']?.find((el) => el.id === mainProductId.value));

    const sizes = ref(['XS', 'S', 'M', 'L', 'XL', 'XXL']);
    const flipAnimateBlock = ref();

    const model = reactive({
      mainProductId,
      id: data.value.products[0]?.id,
    });

    // Img
    const refMainImg = ref();
    const srcMainImg = ref(data.value.photos[0]?.img);

    const changeMainImg = async (src) => {
      if (src === srcMainImg.value) return;
      await animateCSS(refMainImg.value, 'flip-out-ver-right');
      srcMainImg.value = src;
      await animateCSS(refMainImg.value, 'flip-in-ver-right');
    };

    // handlers
    const closeHandler = () => store.commit('modals/hideProduct');

    const toCartHandler = () => {
      flipAnimateBlock.value.classList.add('flip-vertical-right');
      store.commit('cart/addProduct', model);

      setTimeout(() => {
        if (flipAnimateBlock.value) flipAnimateBlock.value.classList.remove('flip-vertical-right');
        if (flipAnimateBlock.value) flipAnimateBlock.value.classList.add('flip-vertical-left');
      }, 3000);
    };

    return {
      closeHandler,
      data,
      srcMainImg,
      changeMainImg,
      sizes,
      model,
      toCartHandler,
      refMainImg,
      flipAnimateBlock,
    };
  },
};
</script>

<style scoped lang="scss">
  .flip-vertical-left {
    animation: flip-vertical-left .6s cubic-bezier(0.73, 0.08, 0.23, 0.87) both;
  }

  @keyframes flip-vertical-left {
    0% {
      transform: rotateY(180deg);
    }
    100% {
      transform: rotateY(0deg);
    }
  }

  .flip-vertical-right {
    animation: flip-vertical-right 1.2s cubic-bezier(0.73, 0.08, 0.23, 0.87) both;
  }

  @keyframes flip-vertical-right {
    0% {
      transform: rotateY(0);
    }
    100% {
      transform: rotateY(900deg);
    }
  }

  .flip-in-ver-right {
    animation: flip-in-ver-right 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  @keyframes flip-in-ver-right {
    0% {
      transform: rotateY(-80deg);
      opacity: 0;
    }
    100% {
      transform: rotateY(0);
      opacity: 1;
    }
  }

  .flip-out-ver-right {
    animation: flip-out-ver-right 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  @keyframes flip-out-ver-right {
    0% {
      transform: rotateY(0);
      opacity: 1;
    }
    100% {
      transform: rotateY(70deg);
      opacity: 0;
    }
  }

  .product-detail {
    display: flex;
    gap: 80px;
    justify-content: space-between;

    &__actions {
      &-animate {
        display: inline-block;
        position: relative;
        width: 140px;
        height: 38px;
        transform-style: preserve-3d;

        .btn:first-child {
          position: absolute;
          white-space: nowrap;
          backface-visibility: hidden;
        }

        .btn:last-child {
          position: absolute;
          white-space: nowrap;
          backface-visibility: hidden;
          transform: rotateY(180deg);
        }
      }
    }

    &__photos {
      display: flex;
      gap: 16px;

      &-nav {
        display: flex;
        flex-direction: column;
        gap: 16px;

        img {
          width: 128px;
          height: auto;
          cursor: pointer;
        }
      }

      &-scrollBox {
        overflow-y: auto;
      }

      &-main {
        .img-card {
          width: 496px;
          height: 640px;
          display: flex;
          justify-content: center;
          align-items: center;

          @include xl {
            width: 400px;
            height: 540px;
          }
          @include lg {
            width: 300px;
            height: 400px;
          }
        }
        img {
          max-height: 640px;
        }
      }
    }

    &__info {
      flex: 1;
      max-width: 720px;

      & > div {
        &:not(:last-child) {
          margin-bottom: 48px;
        }
      }
    }

    &__name {}

    &__radio {
      &-list {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
      }
      &-item {
        font-size: 24px;
        line-height: 100%;
        position: relative;

          input {
            width: 0;
            height: 0;
            opacity: 0;

             + label {
              &::before {
                content: '';
                transition: background-color $transition;
                position: absolute;
                top: calc(100% + 3px);
                left: 0;
                right: 0;
                height: 3px;
                background: transparent;
              }
            }

            &:checked + label {
              &::before {
                background: $primary;
              }
            }

            &:not(:disabled) + label {
              cursor: pointer;
            }

            &:disabled + label {
              color: $gray;
            }
          }
        }
      }

    &__description {
      h4 {
        font-size: 16px;
        line-height: 100%;
        margin-bottom: 24px;
        font-weight: 400;
      }
      p {
        white-space: pre-wrap;
      }
    }
  }
</style>
