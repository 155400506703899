<template>
  <ModalTemplate @close="closeHandler">
    <div class="account">

      <div class="account__header">
        <h3 class="h3 account__email">{{ me.email }}</h3>
        <div class="account__actions">
          <div
            class="account__action"
            role="button"
            @click="showPassHandler"
          >
            <span v-if="!showPassChange">Изменить пароль</span>
            <span v-else>Отмена</span>
          </div>
          <div
            class="account__action"
            role="button"
            @click="exitHandler"
          >
            Выйти
          </div>
        </div>
      </div>

      <form class="account__pass" ref="blockForm" >
        <input
          type="password"
          class="input input_light"
          placeholder="Пароль"
          ref="password"
          required
        >
        <Btn type="secondary" @click.prevent="passChangeHandler">Сменить</Btn>
        <transition
          name="custom-classes"
          enter-active-class="slide-in-left"
          leave-active-class="slide-out-right"
        >
          <div class="account__pass-result" v-if="changePassResult">{{changePassResult}}</div>
        </transition>
      </form>

      <div class="account__row active" ref="blockRow" >

        <div class="account__col">
          <h3 class="h3 account__col-title">История заказов</h3>

          <div class="order" v-for="order in orders" :key="order.id">
            <div class="order__header">
              <div class="order__name">Заказ #{{ order.id }}</div>

              <div class="order__status done" v-if="order.status === 'Received'">Получен</div>
              <div class="order__status" v-else>В ожидании</div>

              <div class="order__date">{{ normalizeDate(order.date) }}</div>
            </div>
            <div class="order__body">
              <div class="order__photos">
                <div
                  v-for="item in order.items"
                  :key="item.id"
                  class="img-card"
                  :style="`background: ${item.color}`"
                >
                  <img v-if="item.img" :src="item.img" alt="" >
                  <p class="order__photos-empty" v-else>нет фото :(</p>
                </div>
              </div>
              <div class="order__price">
                <Currency :price="order.price" type="white" />
              </div>
            </div>
          </div>

        </div>

        <div class="account__col">
          <h3 class="h3 account__col-title">Транзакции</h3>

          <div class="transaction" v-for="item in transactions" :key="item.id">
            <div class="transaction__name">{{ item.description }}</div>
            <div class="transaction__info">
              <div class="transaction__date">
                {{ normalizeDate(item.date) }}
              </div>
              <div class="transaction__price">
                <Currency
                  :addonBefore="item.type === 'plus' ? '+' : '-'"
                  :price="item.amount"
                  :type="item.type === 'plus' ? 'yellow' : 'white'"
                  :priceColor="item.type === 'plus' ? 'yellow' : 'white'"
                />
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>
  </ModalTemplate>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import ModalTemplate from './ModalTemplate.vue';
import Currency from '../Currency.vue';
import { normalizeDate } from '../../utils/helpers';
import Btn from '../Btn.vue';
import { animateCSS } from '../../utils/decorators';

export default {
  name: 'ModalAccount',
  components: { Btn, Currency, ModalTemplate },
  setup() {
    const store = useStore();
    const transactions = ref();
    const orders = ref();
    const products = computed(() => store.getters['products/getProducts']);
    const me = computed(() => store.getters.getUser);

    const blockForm = ref();
    const blockRow = ref();
    const showPassChange = ref(false);
    const showPassHandler = async () => {
      showPassChange.value = !showPassChange.value;

      if (showPassChange.value) {
        await animateCSS(blockRow.value, 'slide-out-right');
        blockRow.value.classList.remove('active');
        blockForm.value.classList.add('active');
        await animateCSS(blockForm.value, 'slide-in-left');
      } else {
        await animateCSS(blockForm.value, 'slide-out-left');
        blockForm.value.classList.remove('active');
        blockRow.value.classList.add('active');
        await animateCSS(blockRow.value, 'slide-in-right');
      }
    };

    const password = ref();
    const changePassResult = ref();
    const passChangeHandler = async () => {
      const res = await store.dispatch('passChange', { password: password.value.value });

      if (res) changePassResult.value = 'Пароль изменен';
      else changePassResult.value = 'Ошибка смены пароля';

      setTimeout(() => { changePassResult.value = null; }, 3000);
    };

    onMounted(async () => {
      transactions.value = await store.dispatch('account/getTransactions');
      const res = await store.dispatch('account/getOrders');

      orders.value = res.map((order) => ({
        ...order,
        items: order.items.map((item) => {
          const productFind = products.value.find((prod) => prod.id === item.product.product.id);
          return {
            ...item,
            img: productFind?.photos[0]?.img,
            color: productFind?.color,
          };
        }),
      }));
    });

    const closeHandler = () => {
      store.commit('modals/hideAccount');
    };

    const changePassHandler = () => {};
    const exitHandler = () => {
      store.commit('clearTokens');
      closeHandler();
    };

    return {
      closeHandler,
      transactions,
      orders,
      normalizeDate,
      products,
      me,
      changePassHandler,
      exitHandler,
      showPassChange,
      showPassHandler,
      blockForm,
      blockRow,
      passChangeHandler,
      password,
      changePassResult,
    };
  },
};
</script>

<style scoped lang="scss">
  .slide-in-left {
    animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  @keyframes slide-in-left {
    0% {
      transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .slide-out-left {
    animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }
  @keyframes slide-out-left {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(-1000px);
      opacity: 0;
    }
  }

  .slide-in-right {
    animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  @keyframes slide-in-right {
    0% {
      transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .slide-out-right {
    animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }
  @keyframes slide-out-right {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(1000px);
      opacity: 0;
    }
  }

  .order {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &:not(:last-child) {
      padding-bottom: 16px;
      margin-bottom: 24px;
      border-bottom: solid 1px #444;
    }

    &__header {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__name {
      font-size: 16px;
      line-height: 150%;
    }

    &__status {
      font-size: 10px;
      line-height: 16px;
      padding: 0 8px;
      border-radius: 16px;
      background-color: #E6E6E6;
      color: $text-dark;

      &.done {
        background-color: $success;
      }
    }

    &__date {
      margin-left: auto;
      font-size: 16px;
      line-height: 24px;
      color: $gray;
      font-family: 'Circe';
    }

    &__body {
      display: flex;
      justify-content: space-between;
    }

    &__photos {
      display: flex;
      gap: 8px;

      &-empty {
        display: flex;
        align-items: center;
        margin: 0;
        width: 54px;
        height: 72px;
        padding: 0 5px;
        white-space: pre-line;
        font-size: .8rem;
        color: $text-dark;
      }

      img {
        width: 54px;
        height: 72px;
        object-fit: contain;
      }
    }
  }

  .transaction {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: solid 1px #444;
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &__date {
      color: $gray;
      font-family: 'Circe';
      margin-bottom: 8px;
      font-size: 16px;
    }
  }

  .account {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__pass {
      flex-direction: column;
      align-items: center;
      gap: 24px;
      display: none;

      &.active {
        display: flex;
      }
    }

    &__email {
      margin-bottom: 16px;
    }

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 50px;
    }

    &__actions {
      display: flex;
      gap: 24px;
    }

    &__action {
      font-size: 16px;
      line-height: 24px;
      color: $gray;
      font-family: 'Circe', sans-serif;
      cursor: pointer;
    }

    &__row {
      align-self: stretch;
      justify-content: space-between;
      gap: 80px;
      display: none;

      &.active {
        display: flex;
      }
    }

    &__col {
      width: 574px;

      &-title {
        margin-bottom: 48px;
      }
    }
  }
</style>
