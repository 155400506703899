<template>
  <div :class="`product product_${idx}`" ref="card">
    <div class="product__img">
      <img v-if="data.photos[0]?.img" :src="data.photos[0]?.img" alt="">
      <p v-else> Нет Фото :(</p>
    </div>
    <div class="product__info">
      <div class="product__name">{{ data.name }}</div>
      <Currency :price="data.price" />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import Currency from './Currency.vue';
import { throttle } from '../utils/decorators';

export default {
  name: 'Product',
  components: { Currency },
  props: {
    data: { type: Object },
    idx: { type: Number },
  },
  setup() {
    const card = ref();

    const mouseMoveHandler = throttle((e) => {
      const halfWidth = card.value.scrollWidth / 2;
      const xAxis = (e.clientX - card.value.getBoundingClientRect().left - halfWidth) / 60;

      const halfHeight = card.value.scrollHeight / 2;
      const yAxis = (e.clientY - card.value.getBoundingClientRect().top - halfHeight) / 60;

      card.value.style.transform = `rotateY(${xAxis}deg) rotateX(${yAxis}deg)`;
    }, 40);

    const mouseOutHandler = throttle((e) => {
      if (!card.value.contains(e.relatedTarget)) {
        card.value.style.transform = null;
      }
    }, 100);

    onMounted(() => {
      card.value.addEventListener('mousemove', mouseMoveHandler);
      card.value.addEventListener('mouseout', mouseOutHandler);
    });

    onBeforeUnmount(() => {
      card.value.removeEventListener('mousemove', mouseMoveHandler);
      card.value.removeEventListener('mousemove', mouseOutHandler);
    });

    return { card };
  },
};
</script>

<style scoped lang="scss">
  .product {
    width: 576px;
    min-height: 768px;
    position: relative;
    padding: 40px;
    cursor: pointer;
    transform-style: preserve-3d;
    transition: transform .1s;
    display: flex;
    flex-direction: column;

    @include xl {
      width: 460px;
      min-height: 614px;
    }

    @include lg {
      width: 380px;
      min-height: 560px;
    }

    &__img {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateZ(200px);

      img {
        max-height: 640px;
      }
    }

    &__info {
      display: flex;
      justify-content: space-between;
      transform: translateZ(100px);
      gap: 22px;
      align-items: flex-end;
    }

    &__name {
      font-size: 48px;
      color: $text-dark;

      @include xl {
        font-size: 26px;
      }
      @include lg {
        font-size: 22px;
      }
    }

    // mods
    &_1 { top: -108px; background: #F5D135; }
    &_2 { top: 108px; background: #F59135; }
    &_3 { top: -108px; background: #35F5E9; }
    &_4 { top: 108px; background: #F5ED35; }
  }
</style>
