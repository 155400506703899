<template>
  <div class="modal">
    <div class="modal__close">
      <button class="modal__close-btn" @click="closeHandler">
        <svg width="32" height="32" viewBox="0 0 20 20" fill="white" xmlns="http://www.w3.org/2000/svg" class="shadow">
          <rect
            x="1.01465"
            y="17.7783"
            width="24"
            height="2"
            transform="rotate(-45 1.01465 17.7783)"
          />
          <rect
            x="2.01465"
            y="0.807617"
            width="24"
            height="2"
            transform="rotate(45 2.01465 0.807617)"
          />
        </svg>
      </button>
    </div>
    <div class="modal__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalTemplate',
  emits: ['close'],
  setup(_, ctx) {
    const closeHandler = () => {
      ctx.emit('close');
    };

    return { closeHandler };
  },
};
</script>

<style scoped lang="scss">
  .modal {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 96px;
    left: 8px;
    right: 8px;
    bottom: 8px;
    transform: translate(0,0);
    z-index: 100;

    &__close {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      top: -88px;
      right: 0;
    }

    &__close-btn {
      padding: 34px;
      border: none;
      background: none;
      cursor: pointer;
    }

    &__content {
      background: #2b2b2c;
      flex: 1;
      padding: 64px 88px;
      color: $text-white;
    }
  }

  .shadow {
    /*filter: drop-shadow(0 0 30);*/
    filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.6))
      drop-shadow(0 0 10px rgba(255, 255, 255, 0.45))
      drop-shadow(0 0 16px rgba(255, 255, 255, 0.25))
      drop-shadow(0 0 12px rgba(255, 255, 255, 0.1));
  }

</style>
