<template>
  <form class="login" ref="form">
    <input type="text" class="input" placeholder="Email" ref="login" required>
    <input type="password" class="input" placeholder="Пароль" ref="password" required>
    <Btn type="secondary" @click.prevent="submitHandler">Войти</Btn>
    <transition name="line">
      <div class="login-error" v-if="errorMsg">{{ errorMsg }}</div>
    </transition>
  </form>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import Btn from './Btn.vue';

export default {
  name: 'Login',
  components: { Btn },
  emits: ['submit'],
  setup(_, ctx) {
    const store = useStore();
    // elements
    const form = ref();
    const login = ref();
    const password = ref();

    const errorMsg = ref();

    // Handlers
    const inputHandler = (e) => {
      e.target.classList.remove('invalid');
      errorMsg.value = null;
    };

    const submitHandler = async () => {
      if (form.value.checkValidity()) {
        const res = await store.dispatch('login', { login: login.value.value, password: password.value.value });

        if (res.detail) errorMsg.value = res.detail;

        if (store.getters.hasTokens) {
          await store.dispatch('getUser');
          ctx.emit('submit');
        }

        return;
      }

      if (!login.value.value) login.value.classList.add('invalid');
      if (!password.value.value) password.value.classList.add('invalid');
    };

    // Hooks
    onMounted(() => {
      form.value.addEventListener('input', inputHandler);
    });

    onBeforeUnmount(() => {
      form.value.removeEventListener('input', inputHandler);
    });

    return {
      form,
      login,
      password,
      submitHandler,
      errorMsg,
    };
  },
};
</script>

<style scoped lang="scss">
  .line-enter-active {
    animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .line-leave-active {
    animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  .login-error {
    color: $danger;
    font-size: .9rem;
    position: absolute;
    left: 0;
    top: calc(100% + 12px);
  }

  .login {
    display: flex;
    gap: 24px;
    position: relative;
  }

  @keyframes slide-in-left {
    0% {
      transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slide-out-right {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(1000px);
      opacity: 0;
    }
  }
</style>
