export function throttle(func, ms = 500) {
  let isThrottled = false;
  let savedArgs;
  let savedThis;

  function wrapper(...args) {
    if (isThrottled) { // (2)
      savedArgs = args;
      savedThis = this;
      return;
    }

    func.apply(this, args); // (1)

    isThrottled = true;

    setTimeout(() => {
      isThrottled = false; // (3)
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = null;
        // eslint-disable-next-line no-param-reassign
        args = null;
      }
    }, ms);
  }

  return wrapper;
}

export function animateCSS(element, animation) {
  return new Promise((resolve) => {
    const animationName = animation;
    const node = (element.nodeType === 1) ? element : document.querySelector(element);

    node.classList.add(...animationName.split(' '));

    function handleAnimationEnd() {
      node.classList.remove(...animationName.split(' '));
      resolve('End');
    }

    node.addEventListener('animationend', handleAnimationEnd, { once: true });
  });
}

export function animate({ timing, draw, duration }) {
  const start = performance.now();

  const anim = (time) => {
    // timeFraction изменяется от 0 до 1
    let timeFraction = (time - start) / duration;
    if (timeFraction > 1) timeFraction = 1;

    // вычисление текущего состояния анимации
    const progress = timing(timeFraction);

    draw(progress); // отрисовать её

    if (timeFraction < 1) {
      requestAnimationFrame(anim);
    }
  };

  requestAnimationFrame(anim);
}

// animate({
//   timing(timeFraction) {
//     return timeFraction;
//   },
//   draw(progress) {
//     console.log(progress);
//     card.value.style.transform = `rotateY(${xAxis}deg) rotateX(${yAxis}deg)`;
//   },
//   duration: 300,
// });
