<template>
  <div class="price">
    <span :style="`color: ${priceColorVal}`">
      {{addonBefore}} {{ price?.toLocaleString('ja-JP') || 0 }}
    </span>

    <div class="price__icon">
      <svg @mouseleave="mouseLeaveHandler" @mouseover="mouseOverHandler" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" :fill="background"/>
        <path
          d="M10.468 10.784C9.83333 10.752 9.364 10.7333 9.06 10.728V13.464C9.36933
          13.4587 9.83867 13.44 10.468 13.408V15H5.82V13.408C6.43333 13.44
          6.9 13.4587 7.22 13.464V10.728C6.9 10.7333 6.43333 10.752 5.82
          10.784V9.192H10.468V10.784ZM14.6291 15.08C13.4237 15.08 12.5357
          14.824 11.9651 14.312C11.3944 13.8 11.1091 13.0693 11.1091
          12.12C11.1091 11.1653 11.3944 10.4107 11.9651 9.856C12.5357
          9.30133 13.4237 9.024 14.6291 9.024C16.3144 9.024 17.4424 9.60267
          18.0131 10.76C17.5331 10.9253 16.9704 11.1733 16.3251 11.504C16.1704
          11.2053 15.9251 10.984 15.5891 10.84C15.2584 10.696 14.9197 10.624
          14.5731 10.624C14.0611 10.624 13.6797 10.76 13.4291 11.032C13.1784
          11.2987 13.0531 11.6293 13.0531 12.024C13.0531 12.4507 13.1864
          12.8 13.4531 13.072C13.7251 13.3387 14.1384 13.472 14.6931
          13.472C15.1357 13.472 15.4877 13.4 15.7491 13.256C16.0104
          13.1067 16.2024 12.872 16.3251 12.552C16.7624 12.7867 17.3224 13.0507
          18.0051 13.344C17.7384 13.9893 17.2931 14.44 16.6691 14.696C16.0451
          14.952 15.3651 15.08 14.6291 15.08Z"
          :fill="color"
        />
      </svg>

      <transition name="tooltip">
        <div class="price__tooltip" v-if="showTooltip">
          <div class="price__tooltip-title">INTERCOIN</div>
          <div class="price__tooltip-text">Внутренняя цифровая валюта компании ГК "Интерстилс"</div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';

export default {
  name: 'Currency',
  props: {
    price: { type: Number, required: true },
    type: { values: ['black', 'yellow', 'white'], default: 'black' },
    priceColor: { values: ['black', 'yellow', 'white'], default: 'white' },
    addonBefore: { type: String },
  },
  setup(props) {
    const background = computed(() => {
      if (props.type === 'black') return '#000';
      if (props.type === 'yellow') return '#F5D135';
      if (props.type === 'white') return '#fff';
      return '#000';
    });
    const color = computed(() => {
      if (props.type === 'black') return '#F5D135';
      if (props.type === 'yellow') return '#000';
      if (props.type === 'white') return '#000';
      return '#F5D135';
    });
    const priceColorVal = computed(() => {
      if (props.priceColor === 'black') return '#000';
      if (props.priceColor === 'yellow') return '#F5D135';
      if (props.priceColor === 'white') return '#fff';
      return '#fff';
    });

    // Hover
    const showTooltip = ref(false);

    const mouseOverHandler = () => {
      showTooltip.value = true;
    };

    const mouseLeaveHandler = () => {
      showTooltip.value = false;
    };

    return {
      background, color, priceColorVal, mouseOverHandler, mouseLeaveHandler, showTooltip,
    };
  },
};
</script>

<style scoped lang="scss">
  .tooltip-enter-active {
    animation: slide-in-blurred-top .6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
  }

  .tooltip-leave-active {
    animation: slide-in-blurred-top reverse 0.3s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
  }

  @keyframes slide-in-blurred-top {
    0% {
      transform: translateY(-50%) scaleY(0.1) scaleX(0.4) translateX(-110%);
      filter: blur(40px);
      opacity: 0;
    }
    100% {
      transform: translateY(0) scaleY(1) scaleX(1) translateX(-75%);
      filter: blur(0);
      opacity: 1;
    }
  }

  .price {
    font-size: 24px;
    color: $text-white;
    display: inline-flex;
    align-items: center;
    gap: 8px;

    &__icon {
      position: relative;
    }

    &__tooltip {
      position: absolute;
      left: 50%;
      top: calc(100% + 16px);
      transform: translateX(-75%);
      background: $white;
      border-radius: 8px;
      color: $text-dark;
      padding: 12px;
      width: 250px;
      z-index: 100;

      &::before {
        content: '';
        position: absolute;
        top: -7px;
        left: 75%;
        transform: translateX(-50%) rotate(45deg);
        width: 20px;
        height: 20px;
        background: $white;
        border-radius: 4px;
      }

      &-title {
        font-size: 16px;
        margin-bottom: 8px;
      }

      &-text {
        font-size: 16px;
        font-family: 'Circe';
        color: $gray;
      }
    }
  }
</style>
