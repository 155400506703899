<template>
   <ModalTemplate @close="closeHandler">
     <div class="cart" v-if="cartItems?.length">

       <div class="cart__title">
         <div class="cart__title-text">Корзина</div>
         <div class="cart__title-action" role="button" @click="cartClear">Удалить все</div>
       </div>

       <div class="cart-items">
         <div class="cart-item" v-for="(item, i) in cartItems" :key="+item.mainProductId + i">
           <div class="cart-item__img">
             <div class="img-card" :style="`background: ${item.color}`">
               <img :src="item.photo" alt="" width="54">
             </div>
           </div>
           <div class="cart-item__info">
             <div class="cart-item__info-name">{{ item.name }}</div>
             <div class="cart-item__info-size">Размер: {{ item.size }}</div>
           </div>

           <div class="cart-item__count">

              <div
                class="cart-item__count-plus"
                @click="countPlus(item.id, item.size)"
              >+</div>

              <div class="cart-item__count-value" >{{ item.count }}</div>

              <div
                class="cart-item__count-minus"
                @click="countMinus(item.id, item.size)"
                v-if="item.count > 1"
              >-</div>

              <div
                class="cart-item__count-remove"
                @click="countMinus(item.id, item.size)"
                v-else
              >x</div>

           </div>

           <div class="cart-item__price">
             <Currency type="yellow" :price="item.price" />
           </div>
         </div>
       </div>

       <div class="cart__footer">
         <div class="cart__footer-price">
           <Currency type="yellow" :price="totalPrice" />
         </div>
         <div class="cart__footer-action">
           <Btn @click="cartSubmit">Оформить</Btn>
         </div>
       </div>

     </div>
     <div class="cart-completed" v-else-if="orderCompleted">Заказ оформлен</div>
     <div class="cart-empty" v-else>В корзине пусто</div>
   </ModalTemplate>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import ModalTemplate from './ModalTemplate.vue';
import Btn from '../Btn.vue';
import Currency from '../Currency.vue';

export default {
  name: 'ModalCart',
  components: { Currency, Btn, ModalTemplate },
  setup() {
    const store = useStore();

    const me = computed(() => store.getters.getUser);
    const products = computed(() => store.getters['products/getProducts']);
    const cart = computed(() => store.getters['cart/getProducts']);
    const orderCompleted = ref(false);

    const cartItems = computed(() => (
      cart.value.map((item) => {
        const prod = products.value.find((p) => p.id === item.mainProductId);
        return {
          ...item,
          name: prod.name,
          price: prod.price * item.count,
          color: prod.color,
          photo: prod.photos[0]?.img,
          size: prod.products.find((el) => el.id === item.id)?.size?.name,
        };
      }).filter((el) => el)
    ));

    const totalPrice = computed(() => (
      [...cartItems.value].reduce((acc, current) => acc + current.price, 0)
    ));

    const countPlus = (id, size) => {
      store.commit('cart/addProduct', { id, size });
    };

    const countMinus = (id, size) => {
      store.commit('cart/removeProduct', { id, size });
    };

    const cartClear = () => {
      store.commit('cart/cartClear');
    };

    const cartSubmit = () => {
      if (me.value) {
        const order = cartItems.value.map((el) => ({
          product_id: el.id,
          quantity: el.count,
        }));
        store.dispatch('cart/createOrder', { items: order });
        orderCompleted.value = true;
      } else {
        store.commit('modals/hideCart');
        store.commit('modals/setEnterFormVisible', 'login');
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };

    const closeHandler = () => {
      store.commit('modals/hideCart');
    };

    return {
      closeHandler,
      cartClear,
      cartItems,
      totalPrice,
      countPlus,
      countMinus,
      cartSubmit,
      orderCompleted,
    };
  },
};
</script>

<style scoped lang="scss">
  .cart-completed {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  }

  @keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }

  .cart-empty {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    font-size: 3rem;
    animation: bounce-top 0.9s both;
  }

  @keyframes bounce-top {
    0% {
      transform: translateY(-45px);
      animation-timing-function: ease-in;
      opacity: 0;
    }
    24% {
      opacity: 1;
    }
    40% {
      transform: translateY(-24px);
      animation-timing-function: ease-in;
    }
    65% {
      transform: translateY(-12px);
      animation-timing-function: ease-in;
    }
    82% {
      transform: translateY(-6px);
      animation-timing-function: ease-in;
    }
    93% {
      transform: translateY(-4px);
      animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
      transform: translateY(0px);
      animation-timing-function: ease-out;
    }
    100% {
      transform: translateY(0px);
      animation-timing-function: ease-out;
      opacity: 1;
    }
  }

  .cart {
    max-width: 574px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 100%;

    &__title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 42px;
      align-items: flex-end;

      &-text { font-size: 24px; }
      &-action {
        font-size: 16px;
        color: $gray;
        font-family: 'Circe';
        cursor: pointer;
      }
    }

    &-items {
      flex: 1;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;

      &:not(:last-child) {
        padding-bottom: 18px;
        margin-bottom: 18px;
        border-bottom: solid 1px #444;
      }

      &__img {
        max-width: 54px;
        max-height: 72px;
      }
      &__info {
        flex: 2;
        align-self: stretch;
        font-size: 16px;
        line-height: 24px;

        &-name { margin-bottom: 4px; }
        &-size { font-family: 'Circe'; color: $gray }
      }
      &__count {
        flex: 1;
        display: flex;
        align-items: center;

        &-plus,
        &-minus,
        &-remove,
        &-value {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          user-select: none;
        }
        &-value {
          cursor: default;
        }
        &-plus {
          color: $primary;
        }
        &-minus,
        &-remove {
          color: $gray;
        }
      }
      &__price {
        flex: 2;

        display: flex;
        justify-content: flex-end;
      }
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
</style>
